import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* NPM */
import "aos/dist/aos.css"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import HowWeDo from "../components/HowWeDo"
import IconsContentMobileApps from "../components/IconsContentMobileApps"

const MobileApps = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")

    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  return (
    <Layout
      showFooter={page.pageSettings.showFooter}
      footerTitle={
        "Ready to transform your content <br> and captivate your audience?"
      }
    >
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="services-page">
        <PageTitleSection
          title={page.headerSettings.title || ""}
          subtitle={page.headerSettings.subTitle || ""}
          description={page.MobileAppsFields.description.copy || ""}
        />

        {page.MobileAppsFields.howWeDoItMobileApps && (
          <HowWeDo {...page.MobileAppsFields.howWeDoItMobileApps[0]} />
        )}

        {page?.MobileAppsFields?.bottomContentMobileApps && (
          <IconsContentMobileApps
            {...page?.MobileAppsFields?.bottomContentMobileApps}
          />
        )}
      </PageWrapper>
    </Layout>
  )
}

export default MobileApps

export const MobileAppsQuery = graphql`
  query MobileAppsId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
      MobileAppsFields {
        howWeDoItMobileApps {
          title
          items {
            title
            copy
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        bottomContentMobileApps {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          copy
          icons {
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        description {
          copy
        }
      }
    }
  }
`
