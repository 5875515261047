import React from "react"

import Section from "./Section"
import { interpolate } from "flubber"
import { useTransform } from "framer-motion"
import { getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import "aos/dist/aos.css"

//User transform
export const getIndex = (_, index) => index

export function useFlubber(progress, pathImage1) {
  return useTransform(progress, pathImage1.map(getIndex), pathImage1, {
    mixer: (a, b) => interpolate(a, b, { maxSegmentLength: 0.1 }),
  })
}

const IconsContentMobileApps = ({ copy, icons, title, image }) => {
  return (
    <>
      <Section additionalClasses="section icons-content has-background-bis has-text-white mobile-apps">
        <div
          className="flex-wrap aos-item"
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="10"
        >
          <div className={`content-left ${!icons?.length ? "single-image" : ""}`}>
            <div
              className="aos-item main-image"
              data-aos="zoom-in"
              data-aos-offset="10"
              data-aos-duration="50"
              data-aos-delay="10"
            >
              <img src={getImage(image.localFile).images.fallback.src} alt="" />
              {icons?.length > 0 && <p className="copy-title">Used by:</p>}
            </div>
            {icons?.length > 0 && (
              <div className="icons-list column is-3">
                <div
                  className="icon-item aos-item"
                  data-aos="zoom-in"
                  data-aos-offset="10"
                  data-aos-duration="50"
                  data-aos-delay="10"
                >
                  <img
                    src={getImage(icons[0].icon.localFile).images.fallback.src}
                    alt=""
                  />
                </div>
                <div
                  className="icon-item aos-item"
                  data-aos="zoom-in"
                  data-aos-offset="10"
                  data-aos-duration="250"
                  data-aos-delay="20"
                >
                  <img
                    src={getImage(icons[1].icon.localFile).images.fallback.src}
                    alt=""
                  />
                </div>
                <div
                  className="icon-item aos-item"
                  data-aos="zoom-in"
                  data-aos-offset="10"
                  data-aos-duration="450"
                  data-aos-delay="30"
                >
                  <img
                    src={getImage(icons[2].icon.localFile).images.fallback.src}
                    alt=""
                  />
                </div>
                <div
                  className="icon-item aos-item"
                  data-aos="zoom-in"
                  data-aos-offset="10"
                  data-aos-duration="650"
                  data-aos-delay="40"
                >
                  <img
                    src={getImage(icons[3].icon.localFile).images.fallback.src}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
          <div className="content-right">
            <div className="title is-size-para is-size-para-mobile title-item has-text-white has-text-weight-normal column is-12">
              <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
                {title ? parse(title) : "Content."}
              </h2>
            </div>
            <div className="is-size-para is-size-para-mobile content-page has-text-white column">
              {copy && parse(copy)}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default IconsContentMobileApps
